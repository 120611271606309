<mat-sidenav-container fullscreen>
    <mat-sidenav-content>
        <app-header></app-header>
        <ng-container>
          <div>
            <div class="row">
              <div class="col-12">
                <h3>Trending</h3>
              </div>
              <div class="col-6">
                <div class="t-img" *ngIf="avaialablePlayBattle!=-1"> <img class="w-100" routerLink="/player-battle"
                    src="/assets/images/playbettal.jfif" alt=""></div>
              </div>
              <div class="col-6">
                <div class="t-img" *ngIf="avaialableContest!=-1"> <img class="w-100" routerLink="/player-contest"
                    src="/assets/images/daily-contest.jfif" alt=""></div>
              </div>
            </div>
          </div>
    
          <div  class="sectiontrending">
    
            <div class="row">
              <div class="col-6">
                <h3>Wheel Spinner</h3>
                <div class="t-img"> <img class="w-100" routerLink="/wheel-spinner" src="/assets/images/spinwin.jfif" alt="">
                </div>
              </div>
             

            </div>

          </div>
        </ng-container>
     

        <app-footer></app-footer>
    </mat-sidenav-content>
</mat-sidenav-container>