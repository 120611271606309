<header>
    <div class="header">
        <mat-toolbar class="mat-toolbar-row contianermobile">
            <div class="left-col">
                <a class="menubutton" (click)="sidenavRight.toggle()" [routerLinkActive]="['active']"> 
                    <mat-icon class>menu</mat-icon>
                </a>
                 
            </div>
            <div class="left-col">
                <span (click)="inplay();"><img src="assets/logo/{{logo}}.png" [ngStyle]="{'max-width':getWidth()}" alt="{{logo}}"></span>
            </div>
            <span class="div-spacer"></span>
            <div class="right-col">
                <ng-container *ngIf="loginButtnStatus">
                   <a mat-stroked-button class="balance" >
                        <!-- <mat-icon (click)="RefreshBal();">refresh</mat-icon> Ba: <br> -->
                        
                     <!-- <mat-icon>exposure</mat-icon> Ex :<br> -->
                    <div style="text-align: right; line-height: 12px;margin-top: 0px;margin-right: 8px; font-size: 10px;">
                      <text (click)="RefreshBal();">  {{walletBalance| number : '0.0-2'}} &nbsp;Bal</text><br> 
                        <text  [routerLink]="['/my-bet']"> {{exposure| number : '0.0-2'}} &nbsp;Exp</text>
                    </div> 
                  </a>
                   <button mat-stroked-button  class="buttonlogin" >
                    <!-- <mat-icon>login</mat-icon> -->
                 <a [routerLink]="['/wallet-deposit/deposit']">
                    <text class="loginbutt">Deposit</text>  
                 </a>
                 </button>
                </ng-container>
                <ng-container *ngIf="!loginButtnStatus">
                 
                 <button mat-stroked-button  class="buttonlogin"  [routerLink]="['/login']" >
                    <!-- <mat-icon>login</mat-icon> -->
                 <a [routerLink]="['/login']">
                    <text class="loginbutt">Login</text>  
                 </a> <text class="loginbutt"> | </text> 
                 <a *ngIf="reg_symbol && this.logo!= 'paisaexch'" [routerLink]="['/register']">
                    <text class="loginbutt">Signup</text>  
                 </a> 
                 </button>
                 <!-- <button mat-stroked-button  class="buttondemoo" >
                 <a *ngIf="reg_symbol && this.logo!= 'paisaexch'" >
                    <text class="demobutt">Demo</text>  
                 </a> 
                 </button> -->
                </ng-container>
            </div>
            
        </mat-toolbar>
        
    </div>
   
    
</header>

<mat-sidenav mode="side" #sidenavRight  position="start"  closed (click)="sidenavRight.toggle()" [routerLinkActive]="['active']" class="right-panel">
    <!-- <div class="sidebar-first-heading">
      
      <div class="close-btn" style="color:#fff"  closed (click)="sidenavRight.toggle()" [routerLinkActive]="['active']"> <mat-icon class="icosize">close</mat-icon></div>
      </div> -->
      <div class="left-col bgheader">
        <span (click)="inplay();"><img src="assets/logo/{{logo}}.png" [ngStyle]="{'max-width':getWidth()}" alt="{{logo}}"></span>
    </div>
    <div class="row user-profile align-items-center" *ngIf="userDetails?.details?.username">
      <div class="col-xs-3">
        <i class="material-icons">person</i>
      </div>
      <div class="col-xs-3 username" >
        {{ userDetails?.details?.username }}
        <div class="last-login">
          <!-- <div>Last login</div>
                  <div>{{moment(userDetails?.details?.updatedAt).utcOffset("+05:30").format('lll')}}</div> -->
        </div>
      </div>
      <!-- <div class="col-xs-3 username" *ngIf="!userDetails?.details?.username">
        demouser
        <div class="last-login">

        </div>
      </div> -->
      <!-- <div class="sidebar-first-heading">
        <button *ngIf="tokenStatus" mat-stroked-button color="warn" (click)="logoutUser()">
          <mat-icon>logout</mat-icon>
        </button>
      </div> -->
      <!-- <div class="close-btn" style="color:#fff"  closed (click)="sidenavRight.toggle()" [routerLinkActive]="['active']"> <mat-icon class="icosize">close</mat-icon></div> -->
    </div>
    <!-- <div class="row" style="margin: 5px 10px">
      <div class="winning-card">
        <a style="color: #000">
          <img src="assets/icons/account_balance.png" />
          <div>Balance</div>
          <h5>
            <b>{{ walletBalance | number : "0.0-2" }}</b>
          </h5>
        </a>
      </div>
  
      <div class="winning-card" *ngIf="userDetails?.details?.manager!='OSG365'">
        <a style="color: #000">
          <i class="material-icons">casino</i>

          <div>Casino</div>
          <h5>
            <b>{{ casinoBal | number : "0.0-2" }}</b>
          </h5>
        </a>
      </div>
  
      <div class="exposure-card">
        <img src="assets/icons/exposure.png" />
        <div>Exposure</div>
        <h5>
          <b>{{ exposure | number : "0.0-2" }}</b>
        </h5>
      </div>
    </div> -->
  
  
  
    <div class="report-menu">
      <!-- <h4>Reports</h4> -->
      
      
      <ul class="menu-list smenu-wrap">
        
        <!-- <li *ngIf="loginButtnStatus" class="smenu-item nav-bonus ng-star-inserted">
          <div  class="smenu-link" >
            <img  onerror="src='../assets/img/default-logo-icon.svg'" alt="Menu Icon" src="https://ss.manage63.com/bmk-wl/commonAssets/sidenav_bonus.svg">
            <span >Bonus</span><span (click)="openModal(template)" class="rules-text">Rules</span>
            <span (click)="openModal(statments)"  class="rules-text statement">Statement</span></div>
            <div><div  class="bonus-wrap">
              <div  class="bonus-details">
                <div  class="bonus-info">
                <p >Total Bonus: 0 </p>
                <div  class="claim-btn">
                  <p >Claimable Bonus: 0 </p>
                  <button  class="btn secondary-btn" disabled=""> Claim </button>
                </div>
                <p  class="n-msg"> Min. Claimable Coins: 100 </p>
              </div>
            </div>
          </div>
        </div>
      </li> -->


        <li>
          <a class="dropdown-item" [routerLink]="['/profile']"><mat-icon class="material-icons iconsize" >person</mat-icon> Profile</a>
        </li>
        <li>
          <a class="dropdown-item" [routerLink]="['/wallet-withdraw/withdraw']"><mat-icon class="material-icons iconsize" >account_balance</mat-icon> Withdrawl details</a>
        </li>
        <li>
          <a class="dropdown-item" [routerLink]="['/ledger']"><mat-icon class="material-icons iconsize" >contacts</mat-icon>Account Statement</a>
        </li>
        <li>
          <a class="dropdown-item" [routerLink]="['/my-bet']"><mat-icon class="material-icons iconsize" >tour</mat-icon>Active Bets</a>
        </li>
        <!-- <li>
          <a class="dropdown-item" ><mat-icon class="material-icons iconsize" >rate_review</mat-icon>Edit Stacks</a>
        </li> -->
        <li>
          <a class="dropdown-item" [routerLink]="['/ledger']"><mat-icon class="material-icons iconsize" >assignment</mat-icon> Ledger</a>
        </li>
        <li>
          <a class="dropdown-item" [routerLink]="['/offer-ledger']"><mat-icon class="material-icons iconsize" >assignment</mat-icon> Offer Ledger</a>
        </li>
        <!-- <li *ngIf="avaialableEventType!='-1' && ListData?.length!=0 && userDetails?.details?.manager!='OSG365'">
          <a class="dropdown-item" (click)="set_fantacy_model()" [routerLink]="['/fixed-deposit']"
          [routerLinkActive]="['active']">
          <i class="material-icons">spoke</i>&nbsp; Investment </a></li> -->
  
  
        <li *ngIf="userDetails?.details?.manager!='OSG365'">
          <a class="dropdown-item" [routerLink]="['/casino-wallet']"><mat-icon class="material-icons iconsize" >account_balance_wallet</mat-icon> Casino
            Wallet</a>
        </li>
        <li *ngIf="userDetails?.details?.manager!='OSG365'">
          <a class="dropdown-item" [routerLink]="['/referAcc']"><mat-icon class="material-icons iconsize" >account_box</mat-icon> Refer Account</a>
        </li>
        <li *ngIf="userDetails?.details?.manager!='OSG365'">
          <a class="dropdown-item" [routerLink]="['/referal-report']"><mat-icon class="material-icons iconsize" >assignment_ind</mat-icon> Referal
            Report</a>
        </li>
        <!-- <li *ngIf="this.avaialableEventType!='-1' || userDetails?.details?.manager!='OSG365'">
          <a class="dropdown-item" [routerLink]="['/fixed-deposit']"><mat-icon class="material-icons iconsize" >account_balance_wallet</mat-icon> Fixed
            Deposit</a>
        </li>
        <li *ngIf="this.avaialableEventType!='-1' || userDetails?.details?.manager!='OSG365'">
          <a class="dropdown-item" [routerLink]="['/fixed-deposit-report']"><mat-icon class="material-icons iconsize" >assignment_ind</mat-icon> Fixed
            Deposit Report</a>
        </li> -->
       
        <li>
          <a class="dropdown-item" [routerLink]="['/wallet-notifications']"><mat-icon class="material-icons iconsize" >notifications</mat-icon>Notifications</a>
        </li>
        <li>
          <a class="dropdown-item" [routerLink]="['/terms-condition']"><mat-icon class="material-icons iconsize" >admin_panel_settings</mat-icon>Rules</a>
        </li>
        <!-- <li>
          <a class="dropdown-item"(click)="openModal(template)" ><mat-icon class="material-icons iconsize" >help</mat-icon>Help</a>
        </li> -->
        <!-- <li *ngIf="tokenStatus" (click)="logoutUser()">
          <a style="color: red !important"> <mat-icon>logout</mat-icon></a> LogOut
        </li> -->
        
        <div class="bottomside">
          <li *ngIf="loginButtnStatus" style="margin-bottom: 8px; line-height: 34px;">
            <a href="https://instagram.com/bet24seven_?igshid=OGQ5ZDc2ODk2ZA==" ><img src="assets/images/osgbook/icon_dark_instagram.svg"></a><a href="https://t.me/Bets24"> <img src="assets/images/osgbook/icon_dark_telegram.svg" ></a>
          </li>
          <li (click)="downloadApk()" class="donnlod">
          <a class="dropdown-item borderdownlo"   style="color:#1a3da6"><mat-icon class="material-icons iconsize"  style="color:#1a3da6">download</mat-icon>Download App</a>
          </li>
          <li  *ngIf="loginButtnStatus"  class= "logdhight" (click)="logoutUser()">
            <a  class="dropdown-item borderlogout" >Logout</a>
          </li>
          <li  *ngIf="!loginButtnStatus" class= "logdhight"  [routerLink]="['/login']">
            <a  class="dropdown-item borderlogout" >Login | SignUp</a>
          </li>
        </div>
       
        
        <!-- <li>
          <a class="dropdown-item" [routerLink]="['/dashboard']"><img src="assets/images/icon/home.png" /> Home</a>
        </li>
        <li>
          <a class="dropdown-item" [routerLink]="['/ledger']"><img src="assets/images/icon/open_bet.png" /> Ledger</a>
        </li>
        <li>
          <a class="dropdown-item" [routerLink]="['/offer-ledger']"><img src="assets/images/icon/open_bet.png" />Offer Ledger</a>
        </li> -->
        <!-- <li *ngIf="avaialableEventType!='-1' && ListData?.length!=0 && userDetails?.details?.manager!='OSG365'">
          <a class="dropdown-item" (click)="set_fantacy_model()" [routerLink]="['/fixed-deposit']"
          [routerLinkActive]="['active']">
          <i class="material-icons">spoke</i>&nbsp; Investment </a></li> -->
  
  
        <!-- <li *ngIf="userDetails?.details?.manager!='OSG365'">
          <a class="dropdown-item" [routerLink]="['/casino-wallet']"><img src="assets/images/wallet.svg" /> Casino
            Wallet</a>
        </li>
        <li *ngIf="userDetails?.details?.manager!='OSG365'">
          <a class="dropdown-item" [routerLink]="['/referAcc']"><img src="assets/images/wallet.svg" /> Refer Account</a>
        </li>
        <li *ngIf="userDetails?.details?.manager!='OSG365'">
          <a class="dropdown-item" [routerLink]="['/referal-report']"><img src="assets/images/wallet.svg" /> Referal
            Report</a>
        </li>
        <li *ngIf="this.avaialableEventType!='-1' || userDetails?.details?.manager!='OSG365'">
          <a class="dropdown-item" [routerLink]="['/fixed-deposit']"><img src="assets/images/wallet.svg" /> Fixed
            Deposit</a>
        </li>
        <li *ngIf="this.avaialableEventType!='-1' || userDetails?.details?.manager!='OSG365'">
          <a class="dropdown-item" [routerLink]="['/fixed-deposit-report']"><img src="assets/images/wallet.svg" /> Fixed
            Deposit Report</a>
        </li>
        <li>
          <a class="dropdown-item" [routerLink]="['/referAcc']"
            ><img src="assets/images/wallet.svg" /> Refer Account</a
          >
        </li> 
        <li>
          <a class="dropdown-item" [routerLink]="['/terms-condition']"><img src="assets/images/icon/report.svg" /> Terms &
            Conditions</a>
        </li> -->
        <!-- <li>
          <a class="dropdown-item" (click)="openModalResetPwd(changePwd)"><img
              src="assets/images/icon/change_stack.png" /> Change Password</a>
        </li> -->
        
      </ul>
    </div>
  </mat-sidenav>
  
  <!-- <ng-template #template>
    <div  class="help-modal">
      <div  class="modal-header  ">
        <h2  style="font-size:14px; font-family: 'Open Sans', sans-serif ; text-align: center;">Help </h2>
        <p>Need Help! Feel free to contact.</p>
        <button  mat-button="" mat-dialog-close="" class="modal-close-btn mdc-button mat-mdc-button mat-unthemed mat-mdc-button-base" mat-ripple-loader-class-name="mat-mdc-button-ripple" type="button">
          <span class="mat-mdc-button-persistent-ripple mdc-button__ripple">
          </span>
          <mat-icon  (click)="modalRef.hide()" role="img" class="mat-icon notranslate material-icons mat-ligature-font mat-icon-no-color" aria-hidden="true" data-mat-icon-type="font"> close </mat-icon>
          <span class="mdc-button__label"></span><span class="mat-mdc-focus-indicator"></span>
          <span class="mat-mdc-button-touch-target"></span>
          <span class="mat-ripple mat-mdc-button-ripple"></span></button>
        </div>
        <div  class="modal-body clubu"><div  class="help-page-wrap">
         
          <button  type="button" style="color:#fff;"> Chat with us on <img  onerror="src='../assets/img/whatsapp-icon.svg'" alt="WhatsApp Icon" src="https://ss.manage63.com/bmk-wl/commonAssets/whatsapp-icon.svg"></button></div></div></div>
    
 </ng-template> -->
 <ng-template #template>
  <div  class="help-modal">
    <div style="padding:16px;font-family: 'Open Sans', sans-serif; font-size:12px">
<table>
  <tr>
    <td style="text-align: center; width:90%">
     <p>Bonus Rules</p> 
    </td>
    <td style="text-align: right;position: relative;left:14px;
    bottom: 11px;">
      <mat-icon class="mat-icon closeup" (click)="modalRef.hide()" >close</mat-icon>
    </td>
  </tr>
</table>

      <p>Bonus Rules w.e.f. 19th Feb 2024</p>
      <p>Bonus coins are valid for 15 days from the date of issue</p>
      <p>Bonus coins can only get matured by playing in any of the cricket games</p>    
      <p>Match Odds / Bookmaker</p>
      <p>0.5% of each bet value or Rs 100 which ever is lower gets matured in the bonus wallet</p>    
<p>For Example:-</p>
<p>You place 7 bets of Rs 20000, so 20000 X 0.5% = Rs 100 x 7 bets = Rs 700 can be claimed and taken to main wallet</p>
<p>You place 5 bets of Rs 50000, so (50000 X 0.5%) = Rs 250 but because there is max capping of Rs 100 per bet that can be matured in the bonus wallet, so Rs 100 X 5 bets = Rs 500 can be claimed and taken to main wallet</p>
<p>Fancy / Toss</p>
<p>2% of each bet value or Rs 100 which ever is lower gets matured in the bonus wallet</p>
<p>For Example:-</p>
<p>You place 7 bets of Rs 5000, so 5000 X 2% = Rs 100 x 7 bets = Rs 700 can be claimed and taken to main wallet</p>
<p>You place 5 bets of Rs 10000, so (10000 X 2%) = Rs 200 but because there is max capping of Rs 100 per bet that can be matured in the bonus wallet, so Rs 100 X 5 bets = Rs 500 can be claimed and taken to main wallet</p>
<p>Matured coins can be claimed and transferred to main wallet when the market exposure is cleared and settled</p>
<p>Minimum Rs 100 can be claimed to main wallet from the bonus wallet</p>
<p>In case of bonus abuse, company holds 100% rights to confesticate your and your related accounts funds without any explanation</p>
<p>For any confusions, please read the above rules multiple times to avoid any disputes later.</p>
      </div>
  </div>
</ng-template>
<ng-template #statments>
  <div  class="help-modal">
    <div style="padding:16px;font-family: 'Open Sans', sans-serif; font-size:12px">
<table>
  <tr>
    <td style="text-align: center; width:90%">
     <p>Bonus Statement</p> 
    </td>
    <td style="text-align: right;position: relative;left:14px;
    bottom: 11px;">
      <mat-icon class="mat-icon closeup" (click)="modalRef.hide()" >close</mat-icon>
    </td>
  </tr>
</table>
<mat-tab-group class="tab-body" mat-align-tabs="center" class="sport-details-tab"> 
  <mat-tab label="Claim History" class="hisbo">
    <ng-template matTabContent>
      <div style="text-align: center; padding: 20px 0px;">
        <p >Not bonus yet.</p>
      </div>
    
    </ng-template>
     </mat-tab>
  <mat-tab label="Bonus History"> 
    <ng-template matTabContent>
      <div  class="bonus-card">
        <div  class="card-row bonus-info">
          <div  class="bonus-detail">
            <p  class="bonus-coin"> 1100 <span >Coins</span></p>
            <div  class="bonus-title"><p style="font-size: 12px;font-weight: 400; opacity: none;">Welcome Bet Bonus</p><p style="opacity: .6;" class="ng-star-inserted">On 4 Mar 2024 05:35 PM</p>
            </div>
          </div>
        </div>
      </div>
    </ng-template>
     </mat-tab>

</mat-tab-group>

      </div>
  </div>
</ng-template>
 
 


