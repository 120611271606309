<mat-sidenav-container fullscreen>
  <mat-sidenav-content>
    <app-header></app-header>
    
    <div class="main-content contins ">
      <div *ngIf="marquetext" class="marquee-text">
        <!-- <mat-icon> rss_feed</mat-icon> -->
        <marquee width="100%" direction="left">{{marquetext}}</marquee>
      </div>

      <!-- offerDesign -->
    
      <div  class="offer_header target" >
        <div class="left"> 
          <a href='#cricket'  [routerLink]="[ '/tabgames/1']"><img src="/assets/images/osgbook/ball.svg" id='cricket' alt=""></a> 
          <p id='cricket' > Cricket</p>
        </div>

        <div class="main">
          <a href='#soccer'  [routerLink]="[ '/tabgames/2']" class='target'>
          <img src="/assets/images/osgbook/soccer.svg"  id='soccer' alt="">
          </a>
          <p>Soccer</p>
        </div>

        <div class="right">
          <a href='#tennis'[routerLink]="[ '/tabgames/3']" class='target'>
          <img src="/assets/images/osgbook/tennis.svg" id='tennis' alt="">
          </a>
          <p>Tennis</p>
        </div>
        <div class="main">
          <a href='#casino' [routerLink]="[ '/tabgames/4']" class='target'>
          <img src="/assets/images/osgbook/cards-header-icon.svg" id='casino' alt="">
          </a>
          <p>Slots</p>
        </div>
        <div class="main">
          <a href='#casino1'  [routerLink]="[ '/tabgames/5']"  class='target'>
          <img src="/assets/images/osgbook/chips-header-icon.svg" id='casino1' alt="">
          </a>

          <p>Live Casino</p>
        </div>
        <div class="main" >
          <a href='#casino2'[routerLink]="[ '/tabgames/6']" class='target'>
          <img src="/assets/images/osgbook/dice-header-icon.svg" id='casino2' alt="">
          </a>
          <p>Casino</p>
        </div>
        <!-- <div class="main" >
          <a href='#fantasy'[routerLink]="[ '/tabgames/7']" class='target'>
          <img src="/assets/images/osgbook/fantasy.svg" id='fantasy' alt="">
          </a>
          <p>Fantasy</p>
        </div> -->
        
        
      </div>
      

      <!-- banner -->

      <!-- bannerEnd -->
     <div style="margin-bottom: 80px;">
</div>
 <ng-container  *ngIf="checkVirtualShow();">
          <div class="game-list-card">
            <div class="game-list-header">
              <!-- <img src="/assets/images/osgbook/ball.svg"   class="balimmage"> -->
              Virtual Cricket 
              <!-- ({{virtualCricketData?.length}}) -->
              <mat-divider class="didvide" [vertical]="true"></mat-divider>
            </div>
            <table class="tabledata">
              <tr>
                <th class="inplayrate" > 
                  <i class="material-icons iconpadd">schedule</i>
                </th>
                <th class="headerinplay ">Team</th>
                <th class="inplayrate">Back</th>
                <th class="inplayrate">Lay</th>
              </tr>
              <tr *ngFor="let x of virtualCricketData" (click)="virtualDetial(x.eventId);" class="bordertbl">
                <td class="borderright"><div class="time-daynew inplay-btn" *ngIf="checkInplayStatus(x.openDate)">In-play</div>
                  <div class="time-daynew" *ngIf="!checkInplayStatus(x.openDate)">
                    <span class="bet-datetime">{{moment(x?.openDate).utcOffset("+05:30").format('MMM')}}
                      {{moment(x?.openDate).utcOffset("+05:30").format('D')}} <br>
                      {{moment(x?.openDate).utcOffset("+05:30").format('hh:mm a')}}</span>
                  </div>
                </td>
                <td class="borderright">
                  <span class="headerinplay">{{x?.eventName}}</span>
                </td>
                <td class="inplayrate ">
                  <div class="odd-list back-rate back-1">
                   <span>{{x?.marketBook?.runners[0]?.availableToBack?.price}}<br/>
                 <small> {{x?.marketBook?.runners[0]?.availableToBack?.size}}</small>
                </span>
                  </div>
                </td>
                  <td class="inplayrate borderright">
                    <div class="odd-list lay-rate lay-1"><span>{{x?.marketBook?.runners[0]?.availableToLay?.price}}<br/>
                      <small>{{x?.marketBook?.runners[0]?.availableToLay?.size}}</small>
                      </span>
                    
                    </div>
                  </td>
              </tr>
            </table>
            <!-- <mat-card *ngFor="let x of virtualCricketData" class="match-event-list m-1"
              (click)="virtualDetial(x.eventId);">

             
              <div class="match-name" fxLayout="row" fxLayoutAlign="start space-arround" fxLayoutGap="5px">
                <div class="time-day inplay-btn" *ngIf="checkInplayStatus(x.openDate)">In-Play</div>
                <div class="time-day" *ngIf="!checkInplayStatus(x.openDate)">
                  <span class="bet-datetime">{{moment(x?.openDate).utcOffset("+05:30").format('MMM')}}
                    {{moment(x?.openDate).utcOffset("+05:30").format('D')}} <br>
                    {{moment(x?.openDate).utcOffset("+05:30").format('hh:mm a')}}</span>
                </div>
                <img src="assets/images/icon/cricket.svg">
                <div class="odd-content">
                  <div class="odd-title">
                    <b>{{x?.eventName}}</b>
                  </div>
                </div>
              </div>
              <div class="event-odds" fxLayout="row" fxLayoutAlign="start space-arround" fxLayoutGap="5px">
                <div class="match-rate">
                  <div class="odd-list back-rate back-1">{{x?.marketBook?.runners[0]?.availableToBack?.price}}</div>
                  <div class="odd-list lay-rate lay-1">{{x?.marketBook?.runners[0]?.availableToLay?.price}}</div>
                  <div class="odd-list back-rate back-2">--</div>
                  <div class="odd-list lay-rate lay-2">--</div>
                  <div class="odd-list back-rate back-3">
                    {{x?.marketBook?.runners[1]?.availableToBack?.price}}</div>
                  <div class="odd-list lay-rate lay-3">
                    {{x?.marketBook?.runners[1]?.availableToLay?.price}}</div>
                </div>
              </div>
            </mat-card> -->
          </div>
        </ng-container>

        <!-- Cricket -->
        <ng-container id="cricket" *ngIf="cricketData?.length">

          
          <div class="game-list-card">
 <div class="game-list-header">
              <table>
              <tr>
                <th>
                  Cricket  
                  <mat-divider class="didvide" [vertical]="true"></mat-divider>   
                </th>
                <th style="text-align: right;" >
                 <a [routerLink]="[ '/tabgames/1']"> <span style="color:#3a3737d4">View All</span>
                </a>
                </th>
              </tr>
              </table>
              <!-- <img src="/assets/images/osgbook/ball.svg"   class="balimmage"> -->
                       
              <!-- ({{cricketData?.length}}) -->


            </div>
            <table class="tabledata">
              <tr>
                <th class="inplayrate" > 
                  <i class="material-icons iconpadd" >schedule</i>
                </th>
                <th class="headerinplay ">Team</th>
                <td>
                  
                </td>
                <th class="inplayrate">Back</th>
                <th class="inplayrate">Lay</th>
              </tr>
              <tr *ngFor="let x of cricketData" (click)="matchDetail(x.eventId);" class="bordertbl">
                <td class="borderright"><div class="time-daynew inplay-btn" *ngIf="checkInplayStatus(x.openDate)">In-play</div>
                  <div class="time-daynew" *ngIf="!checkInplayStatus(x.openDate)">
                    <span class="bet-datetime">{{moment(x?.openDate).utcOffset("+05:30").format('MMM')}}
                      {{moment(x?.openDate).utcOffset("+05:30").format('D')}} <br>
                      {{moment(x?.openDate).utcOffset("+05:30").format('hh:mm a')}}</span>
                  </div>
                </td>
                <td style="padding-left: 8px;line-height: 12px;">
                  <span class="headerinplay">{{x?.eventName}}</span>
                </td>
                <td >
                  <span class="live-tv-icon" *ngIf="x.marketBook.inplay == true">
                    <mat-icon>slideshow</mat-icon>
                </span>
                </td>
                <td class="inplayrate ">
                  <div class="odd-list back-rate back-1">
                   <span>{{x?.marketBook?.runners[0]?.availableToBack?.price}}<br/>
                 <small> {{x?.marketBook?.runners[0]?.availableToBack?.size}}</small>
                </span>
                  </div>
                </td>
                  <td class="inplayrate borderright">
                    <div class="odd-list lay-rate lay-1"><span>{{x?.marketBook?.runners[0]?.availableToLay?.price}}<br/>
                      <small>{{x?.marketBook?.runners[0]?.availableToLay?.size}}</small>
                      </span>
                    
                    </div>
                  </td>
              </tr>
            </table>
            <!-- <mat-card *ngFor="let x of cricketData" class="match-event-list m-1" (click)="matchDetail(x.eventId);">

              <div class="match-name" fxLayout="row" fxLayoutAlign="start space-arround" fxLayoutGap="5px">
                <div class="time-day inplay-btn" *ngIf="checkInplayStatus(x.openDate)">In-Play</div>
                <div class="time-day" *ngIf="!checkInplayStatus(x.openDate)">
                  <span class="bet-datetime">{{moment(x?.openDate).utcOffset("+05:30").format('MMM')}}
                    {{moment(x?.openDate).utcOffset("+05:30").format('D')}} <br>
                    {{moment(x?.openDate).utcOffset("+05:30").format('hh:mm a')}}</span>
                </div>
                <img src="assets/images/icon/cricket.svg">
                <div class="odd-content">
                  <div class="odd-title">
                    <b>{{x?.eventName}}</b>


                  </div>
                </div>
              </div>
              <div class="event-odds" fxLayout="row" fxLayoutAlign="start space-arround" fxLayoutGap="5px">
                <div class="match-rate">
                  <div class="odd-list back-rate back-1">{{x?.marketBook?.runners[0]?.availableToBack?.price}}</div>
                  <div class="odd-list lay-rate lay-1">{{x?.marketBook?.runners[0]?.availableToLay?.price}}</div>
                  <div class="odd-list back-rate back-2">--</div>
                  <div class="odd-list lay-rate lay-2">--</div>
                  <div class="odd-list back-rate back-3">
                    {{x?.marketBook?.runners[1]?.availableToBack?.price}}</div>
                  <div class="odd-list lay-rate lay-3">
                    {{x?.marketBook?.runners[1]?.availableToLay?.price}}</div>
                </div>
              </div>
            </mat-card> -->
          </div>
        </ng-container>
                <!-- Soccer -->
                <ng-container *ngIf="soccerData?.length">
                  <div class="game-list-card">
                    <div class="game-list-header">
                      <table>
                      <tr>
                        <th>
                          Soccer  
                          <mat-divider class="didvide" [vertical]="true"></mat-divider>   
                        </th>
                        <th style="text-align: right;" >
                         <a [routerLink]="[ '/tabgames/2']"> <span style="color:#3a3737d4">View All</span>
                        </a>
                        </th>
                      </tr>
                      </table>
                      <!-- <img src="/assets/images/osgbook/ball.svg"   class="balimmage"> -->
                               
                      <!-- ({{cricketData?.length}}) -->
        
        
                    </div>
                    <table class="tabledata">
                      <tr>
                        <th class="inplayrate" > 
                          <i class="material-icons iconpadd" >schedule</i>
                        </th>
                        <th class="headerinplay ">Team</th>
                        <th></th>
                        <th class="inplayrate">Back</th>
                        <th class="inplayrate">Lay</th>
                      </tr>
                      <tr *ngFor="let x of soccerData" (click)="matchDetail(x.eventId);" class="bordertbl">
                        <td class="borderright"><div class="time-daynew inplay-btn" *ngIf="checkInplayStatus(x.openDate)">In-play</div>
                          <div class="time-daynew" *ngIf="!checkInplayStatus(x.openDate)">
                            <span class="bet-datetime">{{moment(x?.openDate).utcOffset("+05:30").format('MMM')}}
                              {{moment(x?.openDate).utcOffset("+05:30").format('D')}} <br>
                              {{moment(x?.openDate).utcOffset("+05:30").format('hh:mm a')}}</span>
                          </div>
                        </td>
                        <td style="padding-left: 8px;line-height: 12px;">
                          <span class="headerinplay">{{x?.eventName}}</span>
                        </td>
                        <td >
                          <span class="live-tv-icon" *ngIf="x.marketBook.inplay == true">
                            <mat-icon>slideshow</mat-icon>
                        </span>
                        </td>
                        <td class="inplayrate ">
                          <div class="odd-list back-rate back-1">
                           <span>{{x?.marketBook?.runners[0]?.availableToBack?.price}}<br/>
                         <small> {{x?.marketBook?.runners[0]?.availableToBack?.size}}</small>
                        </span>
                          </div>
                        </td>
                          <td class="inplayrate borderright">
                            <div class="odd-list lay-rate lay-1"><span>{{x?.marketBook?.runners[0]?.availableToLay?.price}}<br/>
                              <small>{{x?.marketBook?.runners[0]?.availableToLay?.size}}</small>
                              </span>
                            
                            </div>
                          </td>
                      </tr>
                    </table>
                    <!-- <mat-card *ngFor="let x of soccerData" class="match-event-list m-1" (click)="matchDetail(x.eventId);">
        
                      
                      <div class="match-name" fxLayout="row" fxLayoutAlign="start space-arround" fxLayoutGap="5px">
                        <div class="time-day inplay-btn" *ngIf="checkInplayStatus(x.openDate)">In-Play</div>
                        <div class="time-day" *ngIf="!checkInplayStatus(x.openDate)">
                          <span class="bet-datetime">{{moment(x?.openDate).utcOffset("+05:30").format('MMM')}}
                            {{moment(x?.openDate).utcOffset("+05:30").format('D')}} <br>
                            {{moment(x?.openDate).utcOffset("+05:30").format('hh:mm a')}}</span>
                        </div>
                        <img src="assets/images/icon/soccer.svg">
                        <div class="odd-content">
                          <div class="odd-title">
                            <b>{{x?.eventName}}</b>
                          </div>
                        </div>
                      </div>
                      <div class="event-odds" fxLayout="row" fxLayoutAlign="start space-arround" fxLayoutGap="5px">
                        <div class="match-rate">
                          <div class="odd-list back-rate back-1">{{x?.marketBook?.runners[0]?.availableToBack?.price}}</div>
                          <div class="odd-list lay-rate lay-1">{{x?.marketBook?.runners[0]?.availableToLay?.price}}</div>
                          <div class="odd-list back-rate back-2">--</div>
                          <div class="odd-list lay-rate lay-2">--</div>
                          <div class="odd-list back-rate back-3">
                            {{x?.marketBook?.runners[1]?.availableToBack?.price}}</div>
                          <div class="odd-list lay-rate lay-3">
                            {{x?.marketBook?.runners[1]?.availableToLay?.price}}</div>
                        </div>
                       
                      </div>
        
                    </mat-card> -->
                  </div>
                </ng-container>
<!-- Tennis -->
<ng-container *ngIf="tennisData?.length">
  <div class="game-list-card">
    <div class="game-list-header">
      <table>
      <tr>
        <th>
          Tennis  
          <mat-divider class="didvide" [vertical]="true"></mat-divider>   
        </th>
        <th style="text-align: right;" >
         <a [routerLink]="[ '/tabgames/3']"> <span style="color:#3a3737d4">View All</span>
        </a>
        </th>
      </tr>
      </table>
      <!-- <img src="/assets/images/osgbook/ball.svg"   class="balimmage"> -->
               
      <!-- ({{cricketData?.length}}) -->


    </div>
    <table class="tabledata">
      <tr>
        <th class="inplayrate" > 
          <i class="material-icons iconpadd" >schedule</i>
        </th>
        <th class="headerinplay ">Team</th>
        <th></th>
        <th class="inplayrate">Back</th>
        <th class="inplayrate">Lay</th>
      </tr>
      <tr *ngFor="let x of tennisData" (click)="matchDetail(x.eventId);" class="bordertbl">
        <td class="borderright"><div class="time-daynew inplay-btn" *ngIf="checkInplayStatus(x.openDate)">In-play</div>
          <div class="time-daynew" *ngIf="!checkInplayStatus(x.openDate)">
            <span class="bet-datetime">{{moment(x?.openDate).utcOffset("+05:30").format('MMM')}}
              {{moment(x?.openDate).utcOffset("+05:30").format('D')}} <br>
              {{moment(x?.openDate).utcOffset("+05:30").format('hh:mm a')}}</span>
          </div>
        </td>
        <td style="padding-left: 8px;line-height: 12px;">
          <span class="headerinplay">{{x?.eventName}}</span>
        </td>
        <td >
          <span class="live-tv-icon" *ngIf="x.marketBook.inplay == true">
            <mat-icon>slideshow</mat-icon>
        </span>
        </td>
        <td class="inplayrate ">
          <div class="odd-list back-rate back-1">
           <span>{{x?.marketBook?.runners[0]?.availableToBack?.price}}<br/>
         <small> {{x?.marketBook?.runners[0]?.availableToBack?.size}}</small>
        </span>
          </div>
        </td>
          <td class="inplayrate borderright">
            <div class="odd-list lay-rate lay-1"><span>{{x?.marketBook?.runners[0]?.availableToLay?.price}}<br/>
              <small>{{x?.marketBook?.runners[0]?.availableToLay?.size}}</small>
              </span>
            
            </div>
          </td>
      </tr>
    </table>
    <!-- <mat-card *ngFor="let x of tennisData" class="match-event-list m-1" (click)="matchDetail(x.eventId);">

     
      <div class="match-name" fxLayout="row" fxLayoutAlign="start space-arround" fxLayoutGap="5px">
        <div class="time-day inplay-btn" *ngIf="checkInplayStatus(x.openDate)">In-Play</div>
        <div class="time-day" *ngIf="!checkInplayStatus(x.openDate)">
          <span class="bet-datetime">{{moment(x?.openDate).utcOffset("+05:30").format('MMM')}}
            {{moment(x?.openDate).utcOffset("+05:30").format('D')}} <br>
            {{moment(x?.openDate).utcOffset("+05:30").format('hh:mm a')}}</span>
        </div>
        <img src="assets/images/icon/tennis.svg">
        <div class="odd-content">
          <div class="odd-title">
            <b>{{x?.eventName}}</b>
          </div>
        </div>
      </div>
      <div class="event-odds" fxLayout="row" fxLayoutAlign="start space-arround" fxLayoutGap="5px">
        <div class="match-rate">
          <div class="odd-list back-rate back-1">{{x?.marketBook?.runners[0]?.availableToBack?.price}}</div>
          <div class="odd-list lay-rate lay-1">{{x?.marketBook?.runners[0]?.availableToLay?.price}}</div>
          <div class="odd-list back-rate back-2">--</div>
          <div class="odd-list lay-rate lay-2">--</div>
          <div class="odd-list back-rate back-3">
            {{x?.marketBook?.runners[1]?.availableToBack?.price}}</div>
          <div class="odd-list lay-rate lay-3">
            {{x?.marketBook?.runners[1]?.availableToLay?.price}}</div>
        </div>

    </mat-card> -->
  </div>

</ng-container>

  <div style="margin-bottom: 8px;"></div> 
   <div *ngIf="dataLength" >
    <ng-container *ngIf="!inplay">
      <div class="game-list-card">
        <div class="game-list-header">
              <table>
              <tr>
                <th>
                  Slots  
                  <mat-divider class="didvide" [vertical]="true"></mat-divider>   
                </th>
                <th style="text-align: right;" >
                 <a [routerLink]="[ '/tabgames/4']"> <span style="color:#3a3737d4">View All</span>
                </a>
                </th>
              </tr>
              </table>
              <!-- <img src="/assets/images/osgbook/ball.svg"   class="balimmage"> -->
                       
              <!-- ({{cricketData?.length}}) -->


            </div>
        <ul class="casinolistgrid">
         
          <li><div class="imgframe">
            <div class="usercount">
              <mat-icon class="material-icons sizecasino">people</mat-icon> 6244
                      </div>
            <img  class="imgsize" (click)="openCasino('SPB-aviator')" src="https://client.qtlauncher.com/images/?id=SPB-aviator_en_US&type=logo-square&version=1671525105358"
            alt=""> 
            <div class="game-detail"> <p class="namecasino">
              Casino
            </p> 
            <p class="namecasinom">
              Aviator
            </p> 
            <p class="namecasino bottomi">
              ₹100 - 2L
            </p></div>
          </div></li>
          <li><div class="imgframe">
            <div class="usercount">
              <mat-icon class="material-icons sizecasino">people</mat-icon> 3938
                      </div>
            <img  class="imgsize" (click)="openCasino('DS-aceydeucey')" src="https://client.qtlauncher.com/images/?id=DS-aceydeucey_en_US&type=logo-square&version=1663155620211"
            alt=""> 
    
            <div class="game-detail"> <p class="namecasino">
              Casino
            </p> 
            <p class="namecasinom">
              Acey Deucey
            </p> 
            <p class="namecasino bottomi">
              ₹100 - 2L
            </p></div>
          </div></li>
          <li><div class="imgframe">
            <div class="usercount">
              <mat-icon class="material-icons sizecasino">people</mat-icon> 9376
                      </div>
            <img  class="imgsize" (click)="openCasino('HAK-blocks')"  src="https://client.qtlauncher.com/images/?id=HAK-blocks_en_US&type=logo-square&version=1695090284433"
            alt=""> 
            <div class="game-detail"><p class="namecasino">
              Casino
            </p> 
            <p class="namecasinom">
              Blocks
            </p> 
            <p class="namecasino bottomi">
              ₹100 - 2L
            </p></div>
          </div></li>
          <li><div class="imgframe">
            <div class="usercount">
              <mat-icon class="material-icons sizecasino">people</mat-icon> 8171
                      </div>
            <img  class="imgsize" (click)="openCasino('EVP-bombsquad')" src="https://client.qtlauncher.com/images/?id=EVP-bombsquad_en_US&type=banner&version=1580359967977"
            alt=""> 
            <div class="game-detail"> <p class="namecasino">
              Casino
            </p> 
            <p class="namecasinom">
              Bomb Squad
            </p> 
            <p class="namecasino bottomi">
              ₹100 - 2L
            </p></div>
          </div></li>
          <li><div class="imgframe">
            <div class="usercount">
              <mat-icon class="material-icons sizecasino">people</mat-icon> 170
                      </div>
            <img  class="imgsize" (click)="openCasino('EVP-bonanzawheel')"  src="https://client.qtlauncher.com/images/?id=EVP-bonanzawheel_en_US&type=banner&version=1627007856051"
            alt=""> 
            <div class="game-detail"> <p class="namecasino">
              Casino
            </p> 
            <p class="namecasinom">
              Bonanza Wheel
            </p> 
            <p class="namecasino bottomi">
              ₹100 - 2L
            </p></div>
          </div></li>
          <li><div class="imgframe">
            <div class="usercount">
              <mat-icon class="material-icons sizecasino">people</mat-icon> 2097
                      </div>
            <img  class="imgsize"  (click)="openCasino('WOO-spinthewheel')"  src="https://client.qtlauncher.com/images/?id=WOO-spinthewheel_en_US&type=logo-square&version=1672321747138"
            alt=""> 
            <div class="game-detail"><p class="namecasino">
              Casino
            </p> 
            <p class="namecasinom">
              Spin the Wheel
            </p> 
            <p class="namecasino bottomi">
              ₹100 - 2L
            </p></div>
          </div></li>
          <li><div class="imgframe">
            <div class="usercount">
              <mat-icon class="material-icons sizecasino">people</mat-icon> 3488
                      </div>
            <img  class="imgsize"  (click)="openCasino('EVP-rollthedice')"  src="https://client.qtlauncher.com/images/?id=EVP-rollthedice_en_US&type=logo-square&version=1709649410399"
            alt=""> 
            <div class="game-detail"> <p class="namecasino">
              Casino
            </p> 
            <p class="namecasinom">
              Roll the Dice
            </p> 
            <p class="namecasino bottomi">
              ₹100 - 2L
            </p></div>
          </div></li>
          <li><div class="imgframe">
            <div class="usercount">
              <mat-icon class="material-icons sizecasino">people</mat-icon> 341
                      </div>
            <img  class="imgsize" (click)="openCasino('DS-laddergame')"  src="https://client.qtlauncher.com/images/?id=DS-laddergame_en_US&type=logo-square&version=1663187632616"
            alt=""> 
            <div class="game-detail"><p class="namecasino">
              Casino
            </p> 
            <p class="namecasinom">
              Ladder Game
            </p> 
            <p class="namecasino bottomi">
              ₹100 - 2L
            </p></div>
          </div></li>
          <li><div class="imgframe">
            <div class="usercount">
              <mat-icon class="material-icons sizecasino">people</mat-icon> 5674
                      </div>
            <img  class="imgsize" (click)="openCasino('EVP-headsandtails')"  src="https://client.qtlauncher.com/images/?id=EVP-headsandtails_en_US&type=banner&version=1538453670896"
            alt=""> 
            <div class="game-detail"><p class="namecasino">
              Casino
            </p> 
            <p class="namecasinom">
              Heads & Tails
            </p> 
            <p class="namecasino bottomi">
              ₹100 - 5L
            </p></div>
          </div></li>
          <li><div class="imgframe">
            <div class="usercount">
              <mat-icon class="material-icons sizecasino">people</mat-icon> 3070
                      </div>
            <img  class="imgsize" (click)="openCasino('KIR-igoalengland')"  src="https://client.qtlauncher.com/images/?id=KIR-igoalengland_en_US&type=logo-square&version=1656585205852"
            alt=""> 
            <div class="game-detail"><p class="namecasino">
              Casino
            </p> 
            <p class="namecasinom">
              IGoal England
            </p>
            <p class="namecasino bottomi">
              ₹100 - 2L
            </p></div>
          </div></li>
          <li><div class="imgframe">
            <div class="usercount">
              <mat-icon class="material-icons sizecasino">people</mat-icon> 5074
                      </div>
            <img  class="imgsize" (click)="openCasino('EVP-mehen')"  src="https://client.qtlauncher.com/images/?id=EVP-mehen_en_US&type=banner&version=1612775531674"
            alt=""> 
            <div class="game-detail"><p class="namecasino">
              Casino
            </p> 
            <p class="namecasinom">
              Mehen
            </p>
            <p class="namecasino bottomi">
              ₹100 - 3L
            </p></div>
          </div></li>
          <li><div class="imgframe">
            <div class="usercount">
              <mat-icon class="material-icons sizecasino">people</mat-icon> 3120
                      </div>
            <img  class="imgsize" (click)="openCasino('EVP-pumpkinmaster')"  src="https://client.qtlauncher.com/images/?id=EVP-pumpkinmaster_en_US&type=logo-square&version=1698125393150"
            alt=""> 
            <div class="game-detail"><p class="namecasino">
              Casino
            </p> 
            <p class="namecasinom">
              Pumpkin Master
            </p>
            <p class="namecasino bottomi">
              ₹100 - 3L
            </p></div>
          </div></li>
      </ul>
       
        </div>
        <div class="game-list-card">
          <div class="game-list-header">
            <table>
            <tr>
              <th>
                Live Casino 
                <mat-divider class="didvide" [vertical]="true"></mat-divider>   
              </th>
              <th style="text-align: right;" >
               <a [routerLink]="[ '/tabgames/5']"> <span style="color:#3a3737d4">View All</span>
              </a>
              </th>
            </tr>
            </table>
            <!-- <img src="/assets/images/osgbook/ball.svg"   class="balimmage"> -->
                     
            <!-- ({{cricketData?.length}}) -->


          </div>
          <ul  class="casinolistgrid">
            <li><div class="imgframe">
      
              <div class="usercount">
                <mat-icon class="material-icons sizecasino">people</mat-icon> 9954
                 </div>
              <img  class="imgsize" (click)="openCasino('EVO-americanroulette')" src="https://client.qtlauncher.com/images/?id=EVO-americanroulette_en_US&type=logo-square&version=1678358704943"
              alt=""> 
              <div class="game-detail"> <p class="namecasino">
                Evolution Gaming
              </p> 
              <p class="namecasinom">
                American Roulette
              </p> 
              <p class="namecasino bottomi">
                ₹100 - 25L
              </p></div>
            </div></li>
            <li><div class="imgframe">
              <div class="usercount">
                <mat-icon class="material-icons sizecasino">people</mat-icon> 7467
                        </div>
              <img  class="imgsize"(click)="openCasino('EVO-autolightningroulette')"src="https://client.qtlauncher.com/images/?id=EVO-autolightningroulette_en_US&type=logo-square&version=1701277574098"
              alt=""> 
              <div class="game-detail"> <p class="namecasino">
                Evolution Gaming
              </p> 
              <p class="namecasinom">
                Auto Lightning Roulette
              </p> 
              <p class="namecasino bottomi">
                ₹100 - 25K
              </p></div>
            </div></li>
            <li><div class="imgframe">
              <div class="usercount">
                <mat-icon class="material-icons sizecasino">people</mat-icon> 7668
                        </div>
              <img  class="imgsize" (click)="openCasino('EVO-autoroulettelapartage')" src="https://client.qtlauncher.com/images/?id=EVO-autoroulettelapartage_en_US&type=logo-square&version=1702450712159"
              alt=""> 
              <div class="game-detail">  <p class="namecasino">
                Evolution Gaming
              </p> 
              <p class="namecasinom">
                Autoroulette La Partage
              </p> 
              <p class="namecasino bottomi">
                ₹100 - 50K
              </p></div>
            </div></li>
            <li><div class="imgframe">
              <div class="usercount">
                <mat-icon class="material-icons sizecasino">people</mat-icon> 5676
                        </div>
              <img  class="imgsize"  (click)="openCasino('EVO-bacbo')" src="https://client.qtlauncher.com/images/?id=EVO-bacbo_en_US&type=logo-square&version=1678359207526"
              alt=""> 
              <div class="game-detail">  <p class="namecasino">
                Evolution Gaming
              </p> 
              <p class="namecasinom">
                Bac Bo
              </p> 
              <p class="namecasino bottomi">
                ₹100 - 10L
              </p></div>
            </div></li>
            <li><div class="imgframe">
              <div class="usercount">
                <mat-icon class="material-icons sizecasino">people</mat-icon> 9880
                        </div>
              <img  class="imgsize" (click)="openCasino('EVO-baccarat')" src="https://client.qtlauncher.com/images/?id=EVO-baccarat_en_US&type=logo-square&version=1678359251397"
              alt=""> 
              <div class="game-detail">   <p class="namecasino">
               Evolution Gaming
              </p> 
              <p class="namecasinom">
                Baccarat
              </p> 
              <p class="namecasino bottomi">
                ₹100 - 13L
              </p></div>
            </div></li>
            <li><div class="imgframe">
              <div class="usercount">
                <mat-icon class="material-icons sizecasino">people</mat-icon> 1703
                        </div>
              <img  class="imgsize" (click)="openCasino('EVO-blackjack')" src="https://client.qtlauncher.com/images/?id=EVO-blackjack_en_US&type=logo-square&version=1678359363992"
              alt=""> 
              <div class="game-detail">  <p class="namecasino">
                Evolution Gaming
              </p> 
              <p class="namecasinom">
                Blackjack
              </p> 
              <p class="namecasino bottomi">
                ₹100 - 25L
              </p></div>
            </div></li>
            <li><div class="imgframe">
              <div class="usercount">
                <mat-icon class="material-icons sizecasino">people</mat-icon> 9800
                        </div>
              <img  class="imgsize" (click)="openCasino('EVO-cashorcrash')" src="https://client.qtlauncher.com/images/?id=EVO-cashorcrash_en_US&type=logo-square&version=1678359497656"
              alt=""> 
              <div class="game-detail">   <p class="namecasino">
                Evolution Gaming
              </p> 
              <p class="namecasinom">
                Cash or Crash
              </p> 
              <p class="namecasino bottomi">
                ₹100 - 5L
              </p>
              </div>
            </div></li>
            <li><div class="imgframe">
              <div class="usercount">
                <mat-icon class="material-icons sizecasino">people</mat-icon> 5077
                        </div>
              <img  class="imgsize" (click)="openCasino('EVO-craps')" src="https://client.qtlauncher.com/images/?id=EVO-craps_en_US&type=logo-square&version=1678360187701"
              alt=""> 
              <div class="game-detail">   <p class="namecasino">
                Evolution Gaming
              </p> 
              <p class="namecasinom">
                Craps
              </p> 
              <p class="namecasino bottomi">
                ₹100 - 25L
              </p></div>
            </div></li>
            <li><div class="imgframe">
              <div class="usercount">
                <mat-icon class="material-icons sizecasino">people</mat-icon> 2107
                        </div>
              <img  class="imgsize" (click)="openCasino('EVO-crazycoinflip')" src="https://client.qtlauncher.com/images/?id=EVO-crazycoinflip_en_US&type=logo-square&version=1656329957231"
              alt=""> 
              <div class="game-detail">   <p class="namecasino">
                Evolution Gaming
              </p> 
              <p class="namecasinom">
                Crazy Coin Flip
              </p> 
              <p class="namecasino bottomi">
                ₹100 - 25L
              </p></div>
            </div></li>
            <li><div class="imgframe">
              <div class="usercount">
                <mat-icon class="material-icons sizecasino">people</mat-icon> 4352
                        </div>
              <img  class="imgsize" (click)="openCasino('EVO-dragontiger')" src="https://client.qtlauncher.com/images/?id=EVO-dragontiger_en_US&type=logo-square&version=1678379304276"
              alt=""> 
              <div class="game-detail">   <p class="namecasino">
                Evolution Gaming
              </p> 
              <p class="namecasinom">
                Dragon Tiger
              </p> 
              <p class="namecasino bottomi">
                ₹100 - 15L
              </p></div>
            </div></li>
            <li><div class="imgframe">
              <div class="usercount">
                <mat-icon class="material-icons sizecasino">people</mat-icon> 3490
                        </div>
              <img  class="imgsize" (click)="openCasino('EVO-dreamcatcher')" src="https://client.qtlauncher.com/images/?id=EVO-dreamcatcher_en_US&type=logo-square&version=1678379368909"
              alt=""> 
              <div class="game-detail">   <p class="namecasino">
                Evolution Gaming
              </p> 
              <p class="namecasinom">
                Dream Catcher
              </p> 
              <p class="namecasino bottomi">
                ₹100 - 25L
              </p></div>
            </div></li>
            <li><div class="imgframe">
              <div class="usercount">
                <mat-icon class="material-icons sizecasino">people</mat-icon> 2177
                        </div>
              <img  class="imgsize" (click)="openCasino('EVO-fantan')" src="https://client.qtlauncher.com/images/?id=EVO-fantan_en_US&type=logo-square&version=1678379688682"
              alt=""> 
              <div class="game-detail">   <p class="namecasino">
                Evolution Gaming
              </p> 
              <p class="namecasinom">
                Fan Tan
              </p> 
              <p class="namecasino bottomi">
                ₹100 - 20L
              </p></div>
            </div></li>
        </ul>
         
          </div>
        <div class="game-list-card">
          <div class="game-list-header">
            <table>
            <tr>
              <th>
                Casino 
                <mat-divider class="didvide" [vertical]="true"></mat-divider>   
              </th>
              <th style="text-align: right;" >
               <a [routerLink]="[ '/tabgames/6']"> <span style="color:#3a3737d4">View All</span>
              </a>
              </th>
            </tr>
            </table>
            <!-- <img src="/assets/images/osgbook/ball.svg"   class="balimmage"> -->
                     
            <!-- ({{cricketData?.length}}) -->


          </div>
          <ul  class="casinolistgrid">
            <li><div class="imgframe">
              <div class="usercount">
                <mat-icon class="material-icons sizecasino">people</mat-icon> 6043
                        </div>
              <img  class="imgsize" (click)="openCasino('SPB-aviator')" src="https://cdn.dreamcasino.live/sbe_aviator.webp"
              alt=""> 
              <div class="game-detail"> <p class="namecasino">
                Spribe
              </p> 
              <p class="namecasinom">
                Aviator
              </p> 
              <p class="namecasino bottomi">
                ₹10 - 8K
              </p></div>
            </div></li>
            <li><div class="imgframe">
              <div class="usercount">
                <mat-icon class="material-icons sizecasino">people</mat-icon> 6880
                        </div>
              <img  class="imgsize" (click)="openCasino('EZU-teenpatti')" src="https://client.qtlauncher.com/images/?id=EZU-teenpatti_en_US&type=logo-square&version=1678549135880"
              alt=""> 
              <div class="game-detail">  <p class="namecasino">
                Ezugi
              </p> 
              <p class="namecasinom">
                Teen Patti
              </p> 
              <p class="namecasino bottomi">
                ₹100 - 40K
              </p></div>
            </div></li>
            <li><div class="imgframe">
              <div class="usercount">
                <mat-icon class="material-icons sizecasino">people</mat-icon> 5099
                        </div>
              <img  class="imgsize" (click)="openCasino('EZU-andarbahar')"  src="https://client.qtlauncher.com/images/?id=EZU-andarbahar_en_US&type=logo-square&version=1678476979546"
              alt=""> 
              <div class="game-detail">  <p class="namecasino">
                Ezugi
              </p> 
              <p class="namecasinom">
                Andar Bahar
              </p> 
              <p class="namecasino bottomi">
                ₹100 - 50K
              </p>
              </div>
            </div></li>
            <li><div class="imgframe">
              <div class="usercount">
                <mat-icon class="material-icons sizecasino">people</mat-icon> 2124
                        </div>
              <img  class="imgsize" (click)="openCasino('EVO-megaball')"  src="https://client.qtlauncher.com/images/?id=EVO-megaball_en_US&type=logo-square&version=1678386402736"
              alt=""> 
              <div class="game-detail">   <p class="namecasino">
                Evolution Gaming
              </p> 
              <p class="namecasinom">
                Mega Ball
              </p> 
              <p class="namecasino bottomi">
                ₹100 - 20K
              </p>
              </div>
            </div></li>
            <li><div class="imgframe">
              <div class="usercount">
                <mat-icon class="material-icons sizecasino">people</mat-icon> 3963
                        </div>
              <img  class="imgsize" (click)="openCasino('EZU-onedayteenpatti')" src="https://client.qtlauncher.com/images/?id=EZU-onedayteenpatti_en_US&type=logo-square&version=1678548013373"
              alt=""> 
              <div class="game-detail"> <p class="namecasino">
                Ezugi
              </p> 
              <p class="namecasinom">
                One Day Teen Patti
              </p> 
              <p class="namecasino bottomi">
                ₹100 - 1L
              </p>
              </div>
    
            </div></li>
            <li><div class="imgframe">
              <div class="usercount">
                <mat-icon class="material-icons sizecasino">people</mat-icon> 1690
                        </div>
              <img  class="imgsize" (click)="openCasino('EZU-lucky7')" src="https://client.qtlauncher.com/images/?id=EZU-lucky7_en_US&type=logo-square&version=1678547615073"
              alt=""> 
              <div class="game-detail">
              <p class="namecasino">
                Ezugi
              </p> 
              <p class="namecasinom">
                Lucky 7
              </p> 
              <p class="namecasino bottomi">
                ₹100 - 1L
              </p>
              </div>
            </div></li>
            <li><div class="imgframe">
              <div class="usercount">
                <mat-icon class="material-icons sizecasino">people</mat-icon> 1553
                        </div>
              <img  class="imgsize" (click)="openCasino('EZU-threecardpoker')" src="https://client.qtlauncher.com/images/?id=EVO-sidebetcity_en_US&type=logo-square&version=1678388046310"
              alt=""> 
              <div class="game-detail">
              <p class="namecasino">
                Evolution Gaming
              </p> 
              <p class="namecasinom">
                Side Bet City
              </p> 
              <p class="namecasino bottomi">
                ₹100 - 2L
              </p>
            </div>
            </div></li>
            <li><div class="imgframe">
              <div class="usercount">
                <mat-icon class="material-icons sizecasino">people</mat-icon> 8955
                        </div>
              <img  class="imgsize" (click)="openCasino('EZU-threecardpoker')" src="https://client.qtlauncher.com/images/?id=EZU-threecardpoker_en_US&type=logo-square&version=1687280164981"
              alt=""> 
              <div class="game-detail">
              <p class="namecasino">
                Ezugi
              </p> 
              <p class="namecasinom">
                Three Card Poker
              </p> 
              <p class="namecasino bottomi">
                ₹100 - 1L
              </p>
            </div>
            </div></li>
        </ul>
         
          </div>
          
    </ng-container>
    
   </div>    




        <!-- <ng-container>
          <div *ngIf="!inplay" >
            <div class="row">
              <div class="col-12">
                <h3>Trending</h3>
              </div>
              <div class="col-6">
                <div class="t-img" *ngIf="avaialablePlayBattle!=-1"> <img class="w-100" routerLink="/player-battle"
                    src="/assets/images/playbettal.jfif" alt=""></div>
              </div>
              <div class="col-6">
                <div class="t-img" *ngIf="avaialableContest!=-1"> <img class="w-100" routerLink="/player-contest"
                    src="/assets/images/daily-contest.jfif" alt=""></div>
              </div>
            </div>
          </div>
    
          <div *ngIf="!inplay" class="sectiontrending">
    
            <div class="row">
              <div class="col-6">
                <h3>Wheel Spinner</h3>
                <div class="t-img"> <img class="w-100" routerLink="/wheel-spinner" src="/assets/images/spinwin.jfif" alt="">
                </div>
              </div>
              <div class="col-6">
                <h3 *ngIf="userDetails?.details?.manager!='OSG365'">InPlay</h3>
                <h3 *ngIf="userDetails?.details?.manager=='OSG365'">Virtual</h3>
                <div *ngIf="userDetails?.details?.manager=='OSG365'" class="t-img"> <img class="w-100" (click)="virtualDetial('1234822733')" src="/assets/images/virtual.JPEG"
                  alt=""></div>
                <div *ngIf="userDetails?.details?.manager!='OSG365'" class="t-img"> <img class="w-100" routerLink="/dashboard/inplay" src="/assets/images/bating.png"
                  alt=""></div>
                
              </div>

            </div>
            <div class="row" *ngIf="userDetails?.details?.manager!='OSG365'">
              <div class="col-12">
                <h3>Casino</h3>
              </div>
              <div class="col-3 t-img2">
                <div> <img class="w-100" (click)="openCasino('SPB-aviator')" src="/assets/images/aviator.png"
                    alt=""></div>
              </div>
              <div class="col-3 t-img2">
                <div> <img class="w-100" (click)="openCasino('BPG-jackpotinthebox')" src="/assets/images/download.png"
                    alt=""></div>
              </div>
              <div class="col-3 t-img2">
                <div> <img class="w-100" (click)="openCasino('1x2-coinfield')" src="/assets/images/download2.png"
                    alt=""></div>
              </div>
              <div class="col-3 t-img2">
                <div> <img class="w-100" (click)="openCasino('DS-aceydeucey')" src="/assets/images/download3.png"
                    alt=""></div>
              </div>
            </div>
    
          </div>
        </ng-container> -->
     




      <div *ngIf="offer_data?.length>0 && tokenCheck" class="container offer_icon"
        style="background:url(/assets/background/offer_back.jpg);background-size: cover;background-repeat: no-repeat;background-position: center center;min-height:21vh;">
        <div class="row mx-1">
          <span class="col-12"><i class="fas fa-light fa-gifts"></i><br>
            <h2>BOUNS ON YOUR DEPOSITE</h2>
          </span>
          <ng-container *ngFor="let x of offer_data">
            <div class="col-6">{{x?.percentage}}%</div>
            <div class="col-6">Greter then &#8377;{{x?.amount}}</div>
          </ng-container>
        </div>


      </div>

      <!-- casinoImg -->
      <!-- <ng-container *ngIf="tokenCheck && dataLength">
        <div class="multi-card">
          <div class="scroll-card" style="padding: 14px;">
            <div class="itemsContain">
              <div class="d-flex card-group " style="padding: 0px 5px 0px 5px;">
                <div class="card">
                  <img src="assets/images/casino/85246d801fef4d43a325637243661631_neon-thumbnails-18.png"
                    class="card-img-top" alt="...">
                  <div class="button"><a href="#"> Play Now</a></div>
                </div>

                <div class="card">
                  <img src="assets/images/casino/912af3fca2144874b7b7ab2de03cf63a_neon-thumbnails-13.png"
                    class="card-img-top" alt="...">
                  <div class="button"><a href="#"> Play Now</a></div>
                </div>

              </div>
              <div class="d-flex card-group" style="padding: 0px 5px 0px 5px;">
                <div class="card">
                  <img src="assets/images/casino/bce4691827854b14a631ba020001e847_neon-thumbnails-03.png"
                    class="card-img-top" alt="...">
                  <div class="button"><a href="#"> Play Now</a></div>
                </div>

                <div class="card">
                  <img src="assets/images/casino/7343dec5ab5b4a36a6f926874f216389_neon-thumbnails-02.png"
                    class="card-img-top" alt="...">
                  <div class="button"><a href="#"> Play Now</a></div>
                </div>

              </div>

              <div class="d-flex card-group " style="padding: 0px 5px 0px 5px;">
                <div class="card">
                  <img src="assets/images/casino/bce4691827854b14a631ba020001e847_neon-thumbnails-03.png"
                    class="card-img-top" alt="...">
                  <div class="button"><a href="#"> Play Now</a></div>
                </div>

                <div class="card">
                  <img src="assets/images/casino/7343dec5ab5b4a36a6f926874f216389_neon-thumbnails-02.png"
                    class="card-img-top" alt="...">
                  <div class="button"><a href="#"> Play Now</a></div>
                </div>

              </div>
              <div class="d-flex card-group " style="padding: 0px 5px 0px 5px;">
                <div class="card">
                  <img src="assets/images/casino/0730f21c63544456a0fa1eb250ae4992_neon-thumbnails-17.png"
                    class="card-img-top" alt="...">
                  <div class="button"><a href="#"> Play Now</a></div>
                </div>

                <div class="card">
                  <img src="assets/images/casino/e910c519f92c41a58a9c59dc09e11250_neon-thumbnails-14.png"
                    class="card-img-top" alt="...">
                  <div class="button"><a href="#"> Play Now</a></div>
                </div>

              </div>
              <div class="d-flex card-group " style="padding: 0px 5px 0px 5px;">
                <div class="card">
                  <img src="assets/images/casino/da0706276b4444d491846159bb1bf07a_neon-thumbnails-09.png"
                    class="card-img-top" alt="...">
                  <div class="button"><a href="#"> Play Now</a></div>
                </div>

                <div class="card">
                  <img src="assets/images/casino/1afe8e32eb0d43a496d56979bf5e8b60_neon-thumbnails-10.png"
                    class="card-img-top" alt="...">
                  <div class="button"><a href="#"> Play Now</a></div>
                </div>

              </div>
              <div class="d-flex card-group " style="padding: 0px 5px 0px 5px;">
                <div class="card">
                  <img src="assets/images/casino/89685a59c2dc46429b805875c37066a6_neon-thumbnails-16.png"
                    class="card-img-top" alt="...">
                  <div class="button"><a href="#"> Play Now</a></div>
                </div>

                <div class="card">
                  <img src="assets/images/casino/8e0efab978a14ed483a794f9bb71ff96_neon-thumbnails-19.png"
                    class="card-img-top" alt="...">
                  <div class="button"><a href="#"> Play Now</a></div>
                </div>

              </div>
              <div class="d-flex card-group " style="padding: 0px 5px 0px 5px;">
                <div class="card">
                  <img src="assets/images/casino/d67c61187ab248a58aefdeceff2edd96_neon-thumbnails-06.png"
                    class="card-img-top" alt="...">
                  <div class="button"><a href="#"> Play Now</a></div>
                </div>

                <div class="card">
                  <img src="assets/images/casino/f42e05b9dcee4275b3e2addc2f73c606_neon-thumbnails-08.png"
                    class="card-img-top" alt="...">
                  <div class="button"><a href="#"> Play Now</a></div>
                </div>

              </div>

              <div class="d-flex card-group " style="padding: 0px 5px 0px 5px;">
                <div class="card">
                  <img src="assets/images/casino/c33323b773474acf9f3c43b33e31ae91_neon-thumbnails-12.png"
                    class="card-img-top" alt="...">
                  <div class="button"><a href="#"> Play Now</a></div>
                </div>

                <div class="card">
                  <img src="assets/images/casino/6fbb02d51cf044ea856eaa77faa77cd6_neon-thumbnails-15.png"
                    class="card-img-top" alt="...">
                  <div class="button"><a href="#"> Play Now</a></div>
                </div>

              </div>
              <div class="d-flex card-group " style="padding: 0px 5px 0px 5px;">
                <div class="card">
                  <img src="assets/images/casino/23bce1903ca24717bfa7e6958f026aee_neon-thumbnails-07.png"
                    class="card-img-top" alt="...">
                  <div class="button"><a href="#"> Play Now</a></div>
                </div>

                <div class="card">
                  <img src="assets/images/casino/2b26c9bd6797460dae6697ee093b0825_neon-thumbnails-05.png"
                    class="card-img-top" alt="...">
                  <div class="button"><a href="#"> Play Now</a></div>
                </div>

              </div>
              <div class="d-flex card-group " style="padding: 0px 5px 0px 5px;">
                <div class="card">
                  <img src="assets/images/casino/2594257625a948feaf82aee8447a8cfc_23-RNG-queen.jpg" class="card-img-top"
                    alt="...">
                  <div class="button"><a href="#"> Play Now</a></div>
                </div>

                <div class="card">
                  <img src="assets/images/casino/198dec4fe9634a0493825705892cbd1f_neon-thumbnails-11.png"
                    class="card-img-top" alt="...">
                  <div class="button"><a href="#"> Play Now</a></div>
                </div>

              </div>
              <div class="d-flex card-group " style="padding: 0px 5px 0px 5px;">
                <div class="card">
                  <img src="assets/images/casino/c88fc9c1bfc34e97b8e96a80007e34cf_neon-thumbnails-01.png"
                    class="card-img-top" alt="...">
                  <div class="button"><a href="#"> Play Now</a></div>
                </div>

              </div>

            </div>

          </div>

        </div>
      </ng-container> -->
      <!-- casinoImgEnd -->

      <!-- offerDesign end-->

      <div class="dashboard-page">
       
        <!-- casinoFooter -->
        <ng-container *ngIf="tokenCheck && dataLength">
          <!-- footer -->
          <footer class="text-center text-white" style="background:#fff;">
            <!-- Grid container -->
            <!-- Grid container -->
            <!-- <div class="link">
              <div class="row m-0 no-gutters footer_div">
                <a href="#"> Privacy Policy</a> |
                <a href="#"> About Us</a> |
                <a href="#"> Terms & Conditions </a> |
                <a href="#"> Gambling can be addictive, Please play responsibly</a> |

              </div>
            </div> -->
            <!-- <div class="payment_infor">
              <p>Accept, process & disburse digital payments for your business.</p>
              <img style="width: 91%;" src="https://gamerswar.com/assets/images/social-icons/footer-logo.png"
                alt="Please waiting...">
            </div> -->
            <!-- Copyright -->
            <!-- <div *ngIf="this.logo != 'boomboombook'" class="text-center p-3"
              style="background-color: rgba(0, 0, 0, 0.2);">
              © 2022 Copyright:

              <a class="text-white" href="#">acepunt.in</a>
            </div> -->
            <!-- Copyright -->
          </footer>

          <!-- footerEnd -->

        </ng-container>

        <ng-container *ngIf="dataLength==0">
          <div class=" text-center" style=" color:gray; margin-top:25vh;">No match</div>
        </ng-container>

      </div>

    </div>
    <div style="margin:50px;">

    </div>
    <div  class="floating-btns ">
      <div  class="btn-item">
        <div  class="btn-wrap whatsapp">
         <a href="https://api.whatsapp.com/send/?phone=%2B919090004777&text&type=phone_number&app_absent=0"><img  alt="WhatsApp" src="https://ss.manage63.com/bmk-wl/commonAssets/whatsapp-icon.svg"></a> 
        </div></div></div>

    <app-footer></app-footer>
  </mat-sidenav-content>
  
</mat-sidenav-container>


<button id="openModalButton" [hidden]="true" (click)="openDialog2(myModal)">Open Modal</button>
<ng-template #myModal>
    <div class="modal-body setbody">
        <mat-list class="setmat1">
            <!-- <div class="text-right"><a type="button" class="close1" data-dismiss="modal">
                    <mat-icon (click)="modalRefcancel.hide()">close</mat-icon>
                </a></div> -->
            <div class="text-right">
                <a type="button" class="close1" data-dismiss="modal">
                    <mat-icon (click)="modalRefcancel.hide()">close</mat-icon>
                </a>
               <img style="width:100%"  src="assets/images/offerpoint.png">
            </div>          
        </mat-list>
  
    </div>
  </ng-template>