import { Component, OnDestroy, OnInit} from '@angular/core';
import { Router } from '@angular/router';
import { Socket } from 'ngx-socket-io';
import { ToastrService } from 'ngx-toastr';
import { UsersService } from '../services/users.service';

@Component({
  selector: 'app-fantasy',
  templateUrl: './fantasy.component.html',
  styleUrls: ['./fantasy.component.scss']
})
export class FantasyCompnent implements OnInit{
  avaialablePlayBattle:any;
  avaialableContest:any;
  constructor(private route: Router,private socket: Socket,private toastr: ToastrService,public httpClient:UsersService) 
  {
   
   }

   async ngOnInit(): Promise<void> {
    const usrDetails = await this.getDetials();
    
   }
   async getDetials() {
    try {
      const data = await JSON.parse(sessionStorage.getItem("userDetails"));
      this.avaialablePlayBattle=data?.details?.availableEventTypes.indexOf("3901");
      this.avaialableContest=data?.details?.availableEventTypes.indexOf("3902");
      
      return data;
    } catch (e) {
      return null;
    }
  }
  

}
